import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import * as data from "@data/pledge"
import FormButton from "@components/_atoms/button_form_input_submit"
import RadioButton from "@components/_atoms/button_radio"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import { SlideDown } from "react-slidedown"
import "@components/_atoms/_style/slidedown.scss"
import { childConfirm } from "../../../data/pledge"
import { validate } from "@data/validations"
import * as progress from "@services/progress"
import * as errors from "@services/errors"
import InputPlaces from "@components/_molecules/input_places"

class ChildConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailHelpText: "This can be either the child or parent’s email",
      emailErrorStatus: false,
      citizenship: "",
      address: "",
      streetaddress: "",
      streetHelpText: "Missing Address",
      streetErrorStatus: false,
      apt: "",
      aptHelpText: "Missing apt",
      aptErrorStatus: false,
      city: "",
      cityHelpText: "Missing City",
      cityErrorStatus: false,
      state: "",
      stateHelpText: "Missing State",
      stateErrorStatus: false,
      zipcode: "",
      zipCodeHelpText: "Invalid zip code",
      zipCodeErrorStatus: false,
      generalError: null,
      buttonSubmitted: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let { childAddress, childEmail, parentAddress, parentEmail } = localStorage
    this.setState({
      email: childEmail || parentEmail,
      citizenship: "Yes"
    })
    if (typeof childAddress !== `undefined` && childAddress !== parentAddress) {
      let addrObj = JSON.parse(childAddress)
      this.setState({
        address: "no",
        streetaddress: addrObj.address1 || ``,
        apt: addrObj.address2 || ``,
        city: addrObj.city || ``,
        state: addrObj.province || ``,
        zipcode: addrObj.postalCode || ``
      })
    } else {
      this.setState({
        address: "yes"
      })
    }
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      emailErrorStatus: ""
    })
  }
  handlePlacesChange = state => {
    this.setState({
      state: state.state,
      city: state.city,
      zipcode: state.zipcode,
      streetaddress: state.streetaddress
    })
  }
  handleSubmit = ev => {
    ev.preventDefault()
    if (this.state.address === "yes") {
      let pa = JSON.parse(localStorage.parentAddress)
      this.setState(
        {
          streetaddress: pa.address1,
          apt: pa.address2,
          city: pa.city,
          state: pa.province,
          zipcode: pa.postalCode
        },
        this.sendData
      )
    } else {
      let streetErrorStatus = this.state.streetaddress === "",
        cityErrorStatus = this.state.city === "",
        stateErrorStatus = !validate.state.test(this.state.state),
        zipCodeErrorStatus = !validate.zipCode.test(this.state.zipcode)
      this.setState({
        streetErrorStatus,
        cityErrorStatus,
        stateErrorStatus,
        zipCodeErrorStatus
      })
      if (
        !streetErrorStatus &&
        !cityErrorStatus &&
        !stateErrorStatus &&
        !zipCodeErrorStatus
      ) {
        this.sendData()
      }
    }
  }

  sendData = () => {
    this.setState({ buttonSubmitted: true })
    let _this = this
    let beneficiaryId =
      typeof window !== "undefined" ? localStorage.beneficiaryId : ""
    let query = {
      query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              email
              address1
              address2
              city
              province
              postalCode
              country
            }
          }
        }`,
      variables: `{
        	"updateBeneficiaryUserInput": {
        	  "beneficiaryUserId": "${beneficiaryId}",
            "user": {
        	    "email": "${this.state.email}",
              "address1": "${this.state.streetaddress}",
               "address2": "${this.state.apt}",
               "city": "${this.state.city}",
               "province": "${this.state.state}",
               "postalCode": "${this.state.zipcode}",
               "country": "USA"
            }
          }
        }`
    }
    const options = {
      method: "POST",
      data: JSON.stringify(query),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          let childAddress = {
            address1: this.state.streetaddress,
            address2: this.state.apt,
            city: this.state.city,
            province: this.state.state,
            postalCode: this.state.zipcode
          }
          localStorage.setItem("childAddress", JSON.stringify(childAddress))
          localStorage.setItem("childEmail", this.state.email)
          progress.updateProgress()
          window.location = `/pledge/register/${progress.getNext()}`
        }
      })
      .catch(error => {
        _this.setState({ buttonSubmitted: false })
      })
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  isDisabled = () => {
    if (
      this.state.email === "" ||
      this.state.emailErrorStatus === true ||
      this.state.citizenship === "no" ||
      this.state.citizenship === "" ||
      this.state.buttonSubmitted
    ) {
      return true
    } else {
      return false
    }
  }

  isTheSame = () => {
    if (this.state.address === "no") {
      return false
    } else {
      return true
    }
  }

  render() {
    const { childConfirm } = data
    return (
      <div className="child-confirm">
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <InputLabelState
            data={{
              name: `Email`,
              type: `email`,
              helpText: this.state.emailHelpText,
              errorStatus: this.state.emailErrorStatus,
              defaultVisibility: 1,
              value: this.state.email
            }}
            onChange={this.handleChange}
          />
          <div className="radio citizenship-spacing">
            <p>Child is a US citizen</p>
            <RadioButton
              data={{
                id: "yes",
                name: "citizenship",
                value: "Yes",
                checked: this.state.citizenship === "Yes"
              }}
            >
              Yes
            </RadioButton>
            <RadioButton
              data={{
                id: "no",
                name: "citizenship",
                value: "no",
                checked: this.state.citizenship === "no"
              }}
            >
              No
            </RadioButton>
          </div>

          <div className="radio button-spacing">
            <p>Address same as mine</p>
            <RadioButton
              data={{
                id: "yesAddress",
                name: "address",
                value: "yes",
                checked: this.state.address === "yes"
              }}
            >
              Yes
            </RadioButton>
            <RadioButton
              data={{
                id: "noAddress",
                name: "address",
                value: "no",
                checked: this.state.address === "no"
              }}
            >
              No
            </RadioButton>
          </div>
          <SlideDown closed={this.state.address !== "no"}>
            <div>
              <div className="side-by-side">
                <InputPlaces handlePlacesChange={this.handlePlacesChange} />
                <div>
                  <InputLabelState
                    data={{
                      name: `Apt`,
                      type: `text`,
                      helpText: this.state.aptHelpText,
                      errorStatus: this.state.aptErrorStatus,
                      defaultVisibility: 0,
                      value: this.state.apt
                    }}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <InputLabelState
                data={{
                  name: `City`,
                  type: `text`,
                  helpText: this.state.cityHelpText,
                  errorStatus: this.state.cityErrorStatus,
                  defaultVisibility: 0,
                  value: this.state.city
                }}
                onChange={this.handleChange}
              />
              <div className="side-by-side">
                <InputLabelState
                  data={{
                    name: `State`,
                    type: `text`,
                    helpText: this.state.stateHelpText,
                    errorStatus: this.state.stateErrorStatus,
                    defaultVisibility: 0,
                    value: this.state.state
                  }}
                  onChange={this.handleChange}
                />
                <InputLabelState
                  data={{
                    name: `Zip Code`,
                    type: `text`,
                    helpText: this.state.zipCodeHelpText,
                    errorStatus: this.state.zipCodeErrorStatus,
                    defaultVisibility: 0,
                    value: this.state.zipcode
                  }}
                  onChange={this.handleChange}
                />
              </div>
            </div>{" "}
          </SlideDown>
          <div className="eyebrow-text">
            <p
              className={
                this.state.citizenship === "no" ? `has-error` : `hidden`
              }
            >
              Unable to continue if child is not a US citizen
            </p>
          </div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton isDisabled={this.isDisabled()} />
        </form>
      </div>
    )
  }
}

export default ChildConfirm
