import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import ProgressBar from "@components/_atoms/progress_bar_reg"
import ChildConfirm from "@components/_molecules/child_confirm_pledge"

const InvestEmploymentPage = () => {
  const { childConfirm } = data
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={childConfirm.title} />
      <ProgressBar />
      <BackButton data={childConfirm.backLink} />
      <HeaderAndText data={childConfirm.heading} />
      <ChildConfirm />
    </Layout>
  )
}

export default InvestEmploymentPage
